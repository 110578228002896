import React, { useState, useRef } from 'react';
import { NavLink } from 'react-router-dom';

const Header = () => {
  const [dropdownOpenGetInTouch, setDropdownOpenGetInTouch] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const getInTouchTimeoutRef = useRef(null);

  const handleMouseEnterGetInTouch = () => {
    clearTimeout(getInTouchTimeoutRef.current);
    setDropdownOpenGetInTouch(true);
  };

  const handleMouseLeaveGetInTouch = () => {
    getInTouchTimeoutRef.current = setTimeout(() => {
      setDropdownOpenGetInTouch(false);
    }, 400);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <header className="bg-white shadow-md py-4 px-5 relative z-50">
      <div className="container mx-auto flex justify-between items-center">
        {/* Logo Section */}
        <NavLink to="/" className="text-2xl font-bold">
          <span className="text-black">ACADEMIC</span> <span className="font-light">CONSULTANTS</span>
        </NavLink>

        {/* Hamburger Menu for Mobile */}
        <button
          className="md:hidden text-black focus:outline-none"
          onClick={toggleMobileMenu}
          aria-label="Toggle navigation menu"
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d={isMobileMenuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"}
            ></path>
          </svg>
        </button>

        {/* Navigation Links */}
        <nav className={`md:flex md:space-x-8 ${isMobileMenuOpen ? 'block' : 'hidden'} md:block`}>
          <ul className="flex flex-col md:flex-row md:space-x-8">
            <li>
              <NavLink
                to="/"
                className={({ isActive }) =>
                  `text-black font-semibold relative ${isActive ? "text-[#00AEEF]" : "hover:text-[#00AEEF]"}`
                }
              >
                Home
                <span className="block h-0.5 bg-[#00AEEF] scale-x-0 hover:scale-x-100 transition-transform duration-300 origin-left mt-1"></span>
              </NavLink>
            </li>

            <li>
              <NavLink
                to="/what-we-do"
                className={({ isActive }) =>
                  `text-black font-semibold relative ${isActive ? "text-[#00AEEF]" : "hover:text-[#00AEEF]"}`
                }
              >
                What We Do?
                <span className="block h-0.5 bg-[#00AEEF] scale-x-0 hover:scale-x-100 transition-transform duration-300 origin-left mt-1"></span>
              </NavLink>
            </li>

            {/* Get in Touch Dropdown */}
            <li
              className="relative"
              onMouseEnter={handleMouseEnterGetInTouch}
              onMouseLeave={handleMouseLeaveGetInTouch}
            >
              <NavLink
                to="/what-we-do"
                className={({ isActive }) =>
                  `text-black font-semibold relative ${isActive ? "text-[#00AEEF]" : "hover:text-[#00AEEF]"}`
                }
              >
                Get in Touch <span className="text-xs">&#9662;</span>
                <span className="block h-0.5 bg-[#00AEEF] scale-x-0 hover:scale-x-100 transition-transform duration-300 origin-left mt-1"></span>
              </NavLink>
              {/* Dropdown Menu */}
              {dropdownOpenGetInTouch && (
                <ul className="absolute left-0 mt-2 w-48 bg-white border border-gray-200 shadow-lg z-50">
                  <li>
                    <NavLink
                      to="/looking-for-job"
                      className="block px-4 py-2 text-black hover:bg-gray-100"
                    >
                      Looking For a Job
                    </NavLink>
                  </li>
                </ul>
              )}
            </li>

            <li>
              <NavLink
                to="/current-openings"
                className={({ isActive }) =>
                  `text-black font-semibold relative ${isActive ? "text-[#00AEEF]" : "hover:text-[#00AEEF]"}`
                }
              >
                Current Openings
                <span className="block h-0.5 bg-[#00AEEF] scale-x-0 hover:scale-x-100 transition-transform duration-300 origin-left mt-1"></span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/free-career-tips"
                className={({ isActive }) =>
                  `text-black font-semibold relative ${isActive ? "text-[#00AEEF]" : "hover:text-[#00AEEF]"}`
                }
              >
                Free Career Tips
                <span className="block h-0.5 bg-[#00AEEF] scale-x-0 hover:scale-x-100 transition-transform duration-300 origin-left mt-1"></span>
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
