import React from 'react';

const Cards = () => {
  return (
    <div className="max-w-6xl mx-auto my-12 p-8 bg-white text-center">
      <h2 className="text-4xl font-bold text-gray-800 mb-6">Most Reliable Placement Consultants in Delhi</h2>
      <p className="text-[#01b0f1] font-semibold italic mb-6">
        Disclaimer: <span className="text-[#01b0f1] font-normal">We do not charge any kind of money to
             any candidate throughout the process & do not ask for documents in scan or hard copies.</span>
      </p>
      <p className="text-gray-600 leading-relaxed mb-12">
        With years of working experience in placement consultancy on PAN India level and our hard work, we have
         been able to successfully deliver our customers with excellent services as being best placement
         consultants in Delhi. There is a reason why we are still rocking the market after 10 years
          of existence. Here are our best features as a Manpower Recruitment Agency. Academic Consultants
          has served the industries with wide rage of Placement Consultancy Services, Staffing Agency,
           Recruitment Consultancy Services, HR Consultancy Services in Delhi. Academic Consultants delivers
            placement consultancy services on PAN India basis for
             <span className="text-[#01b0f1] font-semibold">IT companies & Non IT Companies.</span>
      </p>

      {/* Card Section */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        <div className="text-left">
          <img src="/Cards1.jpg" alt="Recruitment of Professionals" className="w-full h-48 object-cover mb-4" />
          <h3 className="text-xl font-semibold text-gray-800 mb-2">
            <a href="https://www.highratecpm.com/yh8hym39n?key=e3824d37603ed34a877bd99e95254c96" target="_blank" rel="noopener noreferrer">
              RECRUITMENT OF PROFESSIONALS
            </a>
          </h3>
          <p className="text-gray-600 mb-4">
            One of the best aspects of our agency is the ability to recruit the best professionals.
             We can offer your company with many highly skilled professionals always working in IT
              & Non IT Recruitment Services.
          </p>
          <a href="https://www.highratecpm.com/yh8hym39n?key=e3824d37603ed34a877bd99e95254c96" className="text-[#01b0f1] font-semibold">Learn more &raquo;</a>
        </div>

        <div className="text-left">
          <img src="Cards2.jpg" alt="Competency Mapping" className="w-full h-48 object-cover mb-4" />
          <h3 className="text-xl font-semibold text-gray-800 mb-2">
            <a href="https://www.highratecpm.com/yh8hym39n?key=e3824d37603ed34a877bd99e95254c96" target="_blank" rel="noopener noreferrer">
              COMPETENCY MAPPING
            </a>
          </h3>
          <p className="text-gray-600 mb-4">
            From JD interviews, Competency Assessment, and Role Assessment, we have a great board of
             competent experts. They can identify and map the abilities of any person who wishes to get employed.
          </p>
          <a href="https://www.highratecpm.com/yh8hym39n?key=e3824d37603ed34a877bd99e95254c96" className="text-[#01b0f1] font-semibold">Learn more &raquo;</a>
        </div>

        <div className="text-left">
          <img src="Cards3.jpg" alt="Psychometric Test" className="w-full h-48 object-cover mb-4" />
          <h3 className="text-xl font-semibold text-gray-800 mb-2">
            <a href="https://www.highratecpm.com/yh8hym39n?key=e3824d37603ed34a877bd99e95254c96" target="_blank" rel="noopener noreferrer">
              PSYCHOMETRIC TEST
            </a>
          </h3>
          <p className="text-gray-600 mb-4">
            How to choose the right career path? Standard and scientific method to evaluate one’s
             mental capabilities and behavioral style. Psychometric Assessment for close to 125
              participants with Consultation.
          </p>
          <a href="https://www.highratecpm.com/yh8hym39n?key=e3824d37603ed34a877bd99e95254c96" className="text-[#01b0f1] font-semibold">Learn more &raquo;</a>
        </div>
      </div>
    </div>
  );
};

export default Cards;
