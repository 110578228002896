import React from 'react';

const WhatWeDo = () => {
  const redirectUrl = 'https://www.highratecpm.com/yh8hym39n?key=e3824d37603ed34a877bd99e95254c96';

  return (
    <section className="max-w-5xl mx-auto my-12 p-8 bg-white">
      <h1 className="text-4xl font-bold text-gray-800 mb-6">
        <a href={redirectUrl} className="text-blue-500 hover:underline">
          Top Recruitment Consultants for IT & Non IT Company for Manpower Recruitment
        </a>
      </h1>
      <p className="text-gray-700 mb-4">
        Academic Consultants have been serving the organizational and industrial community since the last 10 years. Academic is a trusted name when it comes to various HR services. We cater to a wide range of clients across industries like Automotive/Automobile, Construction/Infrastructure, Engineering, Manufacturing, Consumer Durable sectors across IT across India and overseas.
      </p>

      <h2 className="text-2xl font-bold text-gray-800 mb-4">
        <a href={redirectUrl} className="text-blue-500 hover:underline">
          About Academic Consultants
        </a>
      </h2>
      
      <ul className="list-disc list-inside text-gray-700 mb-6">
        <li>We have a quality online databank with us, which are updated, regularly for our set of preferred clients</li>
        <li>Apart from online databank, we use referrals, social networking concepts, associations, head hunting, advertisements and project-based search techniques to source the profiles</li>
        <li>We provide complete end-to-end recruitment solutions as sourcing of profiles to reference checks for our valuable clients</li>
        <li>Our efforts and planning enable our clients to tap this highly talented and select pool in order to maximize business results in the shortest possible time</li>
        <li>We believe in developing long-term relationships with our clients so as to get the optimum share in the growth and achievement of the goals set by the management</li>
      </ul>

      <p className="text-gray-700 mb-4">
        Academic Consultants, India’s fastest-growing & most reliable <a href={redirectUrl} className="text-blue-500 hover:underline">Recruitment Consultants</a>. Academic Consultants armed with more than 12 years of HR domain experienced professionals working as IT Recruitment Agency & Non IT Recruitment Services partner.
      </p>
      
      <p className="text-gray-700">
        Academic Consultants have been functioning for more than 11 years in a wide range of services as <a href={redirectUrl} className="text-blue-500 hover:underline">Placement Consultancy</a> Recruitment Services, Staffing Solutions, HR Services, Head Hunting, Manpower Recruitment.
      </p>
    </section>
  );
};

export default WhatWeDo;
