import React from 'react';

const ContactUs = () => {

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission
    window.location.href = 'https://www.highratecpm.com/yh8hym39n?key=e3824d37603ed34a877bd99e95254c96'; // Redirect to the specified URL
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center items-center py-12">
      <h1 className="text-4xl font-bold text-blue-900 mb-2">Contact Us</h1>
      <p className="text-gray-600 mb-8">
        We’d love to hear from you! Please fill out the form below or reach out through any of the contact methods provided.
      </p>
      
      <div className="w-full max-w-md bg-white rounded-lg shadow-md p-8">
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
            <input 
              type="text" 
              id="name" 
              name="name" 
              className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500" 
              required 
            />
          </div>
          
          <div className="mb-4">
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
            <input 
              type="email" 
              id="email" 
              name="email" 
              className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500" 
              required 
            />
          </div>
          
          <div className="mb-4">
            <label htmlFor="message" className="block text-sm font-medium text-gray-700">Message</label>
            <textarea 
              id="message" 
              name="message" 
              rows="5" 
              className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500" 
              required 
            ></textarea>
          </div>
          
          <button 
            type="submit" 
            className="w-full bg-blue-600 text-white font-semibold py-3 rounded-md hover:bg-blue-700 focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50"
          >
            Send Message
          </button>
        </form>
      </div>
      
      <div className="mt-8 text-center">
        <p className="text-gray-600">If you have any inquiries, you can also reach us at:</p>
        <p className="text-blue-600">
          <a href="mailto:support@academic-consultants.com">support@academic-consultants.com</a>
        </p>
      </div>
    </div>
  );
};

export default ContactUs;
