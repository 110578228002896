import React from 'react';

const Openings = () => {
  const redirectUrl = 'https://www.highratecpm.com/yh8hym39n?key=e3824d37603ed34a877bd99e95254c96';

  const jobs = [
    {
      title: "Data Engineering",
      location: "Delhi",
      type: "Full Time",
      posted: "1 months ago",
      logo: "/opening-logo.png"
    },
    {
      title: "Data Scientist",
      location: "Delhi",
      type: "Full Time",
      posted: "2 weeks ago",
      logo: "/opening-logo.png"
    },
    {
      title: "Java Developer",
      location: "Anywhere",
      type: "Full Time",
      posted: "5 days ago",
      logo: "/opening-logo.png"
    },
    {
      title: "Contract Manager",
      location: "Delhi",
      type: "Full Time",
      posted: "1 months ago",
      logo: "/opening-logo.png"
    },{
      title: "Full-stack Developer",
      location: "Delhi",
      type: "Full Time",
      posted: "5 months ago",
      logo: "/opening-logo.png"
    },
    {
      title: "Frontend Developer",
      location: "Delhi",
      type: "Full Time",
      posted: "3 weeks ago",
      logo: "/opening-logo.png"
    },
    {
      title: "Backend Developer",
      location: "Anywhere",
      type: "Full Time",
      posted: "2 days ago",
      logo: "/opening-logo.png"
    },
    {
      title: "PHP Developer",
      location: "Delhi",
      type: "Full Time",
      posted: "2 months ago",
      logo: "/opening-logo.png"
    }
  ];

  return (
    <section className="max-w-6xl mx-auto my-12 p-4 sm:p-8 bg-white text-center">
      {/* Search Form */}
      <div className="bg-gray-100 p-4 sm:p-6 rounded-lg shadow-md mb-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
          <input 
            type="text" 
            placeholder="Keywords" 
            className="p-3 border border-gray-300 rounded w-full"
          />
          <input 
            type="text" 
            placeholder="Location" 
            className="p-3 border border-gray-300 rounded w-full"
          />
        </div>
        <div className="flex flex-col sm:flex-row justify-between items-center mb-4 space-y-4 sm:space-y-0">
          <label className="flex items-center space-x-2">
            <input type="checkbox" className="form-checkbox" />
            <span>Remote positions only</span>
          </label>
          <a href={redirectUrl}>
            <button className="bg-[#00AEEF] text-white px-6 py-2 rounded w-full sm:w-auto">Search Jobs</button>
          </a>
        </div>
        <div className="grid grid-cols-2 sm:grid-cols-3 gap-2 justify-start">
          <label className="flex items-center space-x-2">
            <input type="checkbox" className="form-checkbox" defaultChecked />
            <span>Freelance</span>
          </label>
          <label className="flex items-center space-x-2">
            <input type="checkbox" className="form-checkbox" defaultChecked />
            <span>Full Time</span>
          </label>
          <label className="flex items-center space-x-2">
            <input type="checkbox" className="form-checkbox" defaultChecked />
            <span>Internship</span>
          </label>
          <label className="flex items-center space-x-2">
            <input type="checkbox" className="form-checkbox" defaultChecked />
            <span>Part Time</span>
          </label>
          <label className="flex items-center space-x-2">
            <input type="checkbox" className="form-checkbox" defaultChecked />
            <span>Temporary</span>
          </label>
        </div>
      </div>

      {/* Job Listings */}
      <ul className="space-y-4">
        {jobs.map((job, index) => (
          <li key={index} className="flex flex-col sm:flex-row items-center justify-between p-4 border-b border-gray-200">
            <div className="flex items-center space-x-4 mb-4 sm:mb-0">
              <img src={job.logo} alt={job.title} className="w-12 h-12" />
              <div>
                <a href={redirectUrl} className="text-lg text-left font-semibold text-gray-800 hover:underline">
                  {job.title}
                </a>
                <p className="text-gray-500 text-left">{job.location}</p>
              </div>
            </div>
            <div className="text-right">
              <span className="block text-green-600 font-semibold">{job.type}</span>
              <span className="block text-gray-500 text-sm">{job.posted}</span>
            </div>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default Openings;
