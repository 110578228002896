import React from 'react';
import HeroSection from './Components/HeroSection';
import Cards from './Components/Cards';
import Best from './Components/Best';
import ClientTestimonial from './Components/ClientTestimonial';


function App() {
  return (
    <main className='Relative overflow-hidden'>
      <section><HeroSection /></section>
      <section><Cards /></section>
      <section><Best /></section>
      <section><ClientTestimonial /></section>
      </main>
  );
}

export default App;
