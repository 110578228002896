import React from 'react';

const Best = () => {
  return (
    <section className="max-w-6xl mx-auto my-12 p-8 bg-white text-center">
      <p className="text-gray-600 uppercase tracking-wide mb-4">
        Placement Consultants delivering service in Delhi | Mumbai | Chennai | Ahmedabad | Bangalore | Gurgaon | Nagpur | Pune | Nasik
      </p>
      
      <h2 className="text-4xl font-bold text-gray-800 mb-6">
        Best Placement Consultants in Delhi
      </h2>
      
      <p className="text-gray-600 leading-relaxed mb-8">
        Our consistent performance and top-notch support over the years have made us one of the <span className="font-bold">Best Placement Consultants in Delhi</span>.
        We have the ability to deliver our services in both IT and Non-IT sector industries in the country. We focus on customer satisfaction and that is why we never compromise on the quality of service that we offer you with.
        Client requirements, job profile requirements, and candidate requirements are some of our most important fields of work.
      </p>
      
      <a href="https://www.highratecpm.com/yh8hym39n?key=e3824d37603ed34a877bd99e95254c96" className="bg-[#01b0f1] text-white px-6 py-3 rounded-full text-lg font-semibold hover:bg-[#0093cc] mb-8 inline-block">
        Get Associated With Us
      </a>

      <div className="mt-12 grid grid-cols-1 md:grid-cols-3 gap-8">
        <img src="Best1.jpg" alt="group of professionals" className="w-full h-auto object-cover" />
        <img src="Best2.jpg" alt="Another group of professionals" className="w-full h-auto object-cover" />
        <img src="Best3.png" alt="Another group of professionals 2" className="w-full h-auto object-cover" />
      </div>
    </section>
  );
};

export default Best;
