import React from 'react';

const FreeCareerTips = () => {
  const redirectUrl = 'https://www.highratecpm.com/yh8hym39n?key=e3824d37603ed34a877bd99e95254c96';

  const posts = [
    {
      id: 1,
      title: "Income Tax Refund Status | ITR e filing",
      description: "How Many Days It Takes to Get a Tax Refund: After Filing ITR, When Will I Get Refund? Filing Income...",
      tags: ["FinancialPlanning", "Income tax refund", "income tax refund status", "TaxRefund"],
      categories: ["Career Consultants", "Career Tips"],
      image: "",
      link: redirectUrl,
    },
    {
      id: 2,
      title: "Top 10 Manpower Consultancies in Pune",
      description: "Top 10 Manpower Consultancies in Pune 1. Accord Consultants Overview: Accord Consultants has established itself as the leading manpower consultancy...",
      tags: [],
      categories: ["Career Consultants"],
      image: "",
      link: redirectUrl,
    },
    {
      id: 3,
      title: "New Job Opportunities after Lok Sabha 2024 Elections in India",
      description: "The Lok Sabha elections in India have always been a pivotal moment in the nation's political and economic landscape. As...",
      tags: [],
      categories: ["Career Consultants"],
      image: "/loksabha.jpg",
      link: redirectUrl,
    },
    {
        id: 4,
        title: "How to Write a Good Job Description: 7 Best Tips for Success",
        description: "Unlocking Success: The Art of Writing an Effective Job Description The importance of an effective job description In today's such...",
        tags: [],
        categories: ["Career Consultants"],
        image: "/Career1.webp",
        link: redirectUrl,
      }
      ,{
        id: 5,
        title: "Jobs in Indore",
        description: "As the economic hub of Madhya Pradesh, Indore has witnessed significant growth in recent years. Its job market is thriving,...",
        tags: [],
        categories: ["Career Consultants"],
        image: "/Career2.jpg",
        link: redirectUrl,
      },
      {
        id: 6,
        title: "How to Choose the Best IT Placement Consultancy",
        description: "How to Choose the Best IT Placement Consultancy Tech advancements are still scratching the surface of concepts like artificial intelligence...",
        tags: [],
        categories: ["Artificial Intelligence"],
        image: "/Career3.jpg",
        link: redirectUrl,
      }
  ];

  return (
    <section className="max-w-7xl mx-auto my-12 p-8 bg-white">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {posts.map((post) => (
          <div key={post.id} className="bg-white rounded-lg shadow-md p-4">
            {post.image && (
              <img src={post.image} alt={post.title} className="w-full h-48 object-cover rounded-t-lg mb-4" />
            )}
            <div className="mb-2">
              {post.categories.map((category, index) => (
                <span 
                  key={index} 
                  className="bg-green-500 text-white text-xs font-semibold px-2 py-1 rounded-full mr-2">
                  {category}
                </span>
              ))}
            </div>
            <h3 className="text-xl font-semibold text-gray-800 mb-2">
              {post.title}
            </h3>
            <p className="text-gray-600 mb-4">
              {post.description}
            </p>
            <a href={post.link} className="inline-block bg-white border border-gray-400 text-gray-800 px-4 py-2 rounded hover:bg-gray-100">
              Read More
            </a>
            {post.tags.length > 0 && (
              <p className="text-gray-400 text-sm mt-4">
                Tags: {post.tags.join(", ")}
              </p>
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

export default FreeCareerTips;
