

const ClientTestimonial = () => {
  return (
    <section className="max-w-6xl mx-auto my-12 p-8 bg-white text-center">
      {/* Section Title */}
      <h2 className="text-4xl font-bold text-gray-800 mb-4">Client Testimonials</h2>
      <p className="text-gray-600 mb-12">
        Don’t take our word for it – here’s why our clients say: Best Placement Consultants in
         Delhi is Academic Consultants
      </p>

      {/* Testimonials Grid */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {/* Testimonial 1 */}
        <div className="p-6 border rounded-lg shadow-lg">
          <div className="text-[#01b0f1] text-4xl mb-4">❝</div>
          <p className="text-gray-600 mb-6">
            Many thanks to Accademic Consultants in handling my end to end manpower recruitment so swiftly.
            Really handled in such a professional way and with quality work. Most of the candidate recruited
            from Academic Consultants.
          </p>
          <div className="flex flex-col items-center">
            <img src="hr2.jfif" alt="Anjeet" className="w-20 h-20 rounded-full mb-4 object-cover" />
            <h3 className="text-lg font-bold text-gray-800">Anjeet</h3>
            <p className="text-gray-500">Assist. Manager Human Resource & Admin</p>
          </div>
        </div>

        {/* Testimonial 2 */}
        <div className="p-6 border rounded-lg shadow-lg">
          <div className="text-[#01b0f1] text-4xl mb-4">❝</div>
          <p className="text-gray-600 mb-6">
            I have never experienced such a professional way of working which Academic does.
            Quality database. Thanks for settling competency mapping in our organisation in a short
            period of time in a graceful way. Indeed, best placement consultants in Delhi India.
          </p>
          <div className="flex flex-col items-center">
            <img src="hr1.webp" alt="Siddharth Khanna" className="w-20 h-20 rounded-full mb-4 object-cover" />
            <h3 className="text-lg font-bold text-gray-800">Siddharth Khanna</h3>
            <p className="text-gray-500">Senior HR</p>
          </div>
        </div>

        {/* Testimonial 3 */}
        <div className="p-6 border rounded-lg shadow-lg">
          <div className="text-[#01b0f1] text-4xl mb-4">❝</div>
          <p className="text-gray-600 mb-6">
            We would like to appreciate the effort taken by your team in recruitment process
            and I can proudly say that we have quality candidate recruited in our organisation
            provided by you Academic. In few resumes we got right candidates.
          </p>
          <div className="flex flex-col items-center">
            <img src="hr3.jfif" alt="Astha Sukhla" className="w-20 h-20 rounded-full mb-4 object-cover" />
            <h3 className="text-lg font-bold text-gray-800">Astha Sukhla</h3>
            <p className="text-gray-500">Tech Consultant</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ClientTestimonial;
