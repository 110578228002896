import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './index.css';
import App from './App';
import Header from './Components/Headers';
import Footer from './Components/Footer';
import Openings from './Components/Openings';
import ScrollToTop from './Components/ScrollToTop';
import FreeCareerTips from './Components/FreeCareerTips';
import LookingForJob from './Components/LookingForJob';
import WhatWeDo from './Components/WhatWeDo';
import PrivacyPolicy from './Components/PrivacyPolicy';
import Disclaimer from './Components/Disclaimer';
import TandC from './Components/TandC'
import ContactUs from './Components/ContactUs';


ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Router>
      <Header />
      <ScrollToTop />
      <Routes>
        <Route path='/' element={<App />} />
        <Route path='current-openings' element={<Openings />} />
        <Route path='free-career-tips' element={<FreeCareerTips />} />
        <Route path='Looking-for-job' element ={<LookingForJob/>} />
        <Route path='what-we-do' element={<WhatWeDo/>} />
        <Route path='privacy-policy' element={<PrivacyPolicy/>} />
        <Route path='Disclaimer' element={<Disclaimer/>} />
        <Route path='TandC' element={<TandC />} />
        <Route path='contact-us' element={<ContactUs/>} />
      </Routes>
      <Footer/>
    </Router>
  </React.StrictMode>
)